var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-1"
  }, [_c('Search', {
    staticClass: "mb-75",
    attrs: {
      "default-search-data": _vm.payloadSearch,
      "is-disable": false,
      "is-class-booking": true
    },
    on: {
      "handleSearch": _vm.handleSearch
    }
  }), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('BCard', {
    staticClass: "rounded-lg mb-50 mb-lg-1 mt-50",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "p-0 rounded_header",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('BRow', {
          staticClass: "w-100 mx-0"
        }, [_c('BCol', {
          staticClass: "d-flex align-items-center text-white cursor-pointer border-right px-25 px-sm-1",
          attrs: {
            "cols": "6"
          },
          on: {
            "click": _vm.toggleVisibleCheapestHandle
          }
        }, [_c('span', {
          class: "fw-700 mr-1 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.neighboringDays')) + " ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "py-25 rotate_class text-white",
          style: {
            transform: _vm.rotateStyleCheapest,
            width: '18px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)]), _c('BCol', {
          staticClass: "border-left d-flex align-items-center justify-content-end",
          attrs: {
            "cols": "6"
          },
          on: {
            "click": _vm.handleToggleFilter
          }
        }, [_c('span', {
          class: "fw-700 text-white mr-1 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.filters')) + " ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "fw-600 font-medium-2",
          attrs: {
            "src": require('@icons/filter-white.svg'),
            "blank-color": "#ccc"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 1683513973)
  }, [_c('BCollapse', {
    model: {
      value: _vm.isVisibleCheapestFare,
      callback: function callback($$v) {
        _vm.isVisibleCheapestFare = $$v;
      },
      expression: "isVisibleCheapestFare"
    }
  }, [_c('div', [_c('CheapFareSlideClassBooking', {
    staticClass: "mb-50"
  })], 1)])], 1) : _vm._e(), _c('BRow', {
    staticClass: "mt-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('BCol', {
    staticClass: "pr-lg-50",
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "9"
    }
  }, [['lg', 'xl'].includes(_vm.appBreakPoint) && _vm.isVisibleCheapestFare ? _c('CheapFareSlideClassBooking') : _vm._e(), _c('ResultSearchClassBooking', {
    on: {
      "scrollToTarget": _vm.scrollToTarget,
      "scrollToCardShowPrice": _vm.scrollToCardShowPrice
    }
  })], 1), _c('BCol', {
    staticClass: "d-none d-lg-inline",
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('div', [_c('BCard', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    class: "my-50 mx-25 border-info ".concat(_vm.isCardCheapestFareDisabled ? 'cursor-not-allowed' : 'cursor-pointer'),
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "py-25 px-25",
      "no-body": "",
      "disabled": _vm.isCardCheapestFareDisabled
    },
    on: {
      "click": _vm.handleCardCheapestFareClick
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [!_vm.isCardCheapestFareDisabled ? _c('div', {
          staticClass: "w-100 d-flex align-items-center justify-content-around flex-nowrap"
        }, [_c('b-img', {
          attrs: {
            "src": require('@icons/expland-left-double.svg'),
            "blank-color": "#ccc",
            "width": "21"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.$t("flight.".concat(_vm.isVisibleCheapestFare ? 'hideCheapestFareSwiper' : 'showCheapestFareSwiper'))))]), _c('b-img', {
          attrs: {
            "src": require('@icons/date-info.svg'),
            "blank-color": "#ccc",
            "width": "21"
          }
        })], 1) : _c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "variant": "info"
          }
        }), _c('span', {
          staticClass: "text-info ml-25"
        }, [_vm._v(_vm._s(_vm.$t('flight.Processing')))])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('FilterClassBooking')], 1)], 1)], 1), _c('div', {
    ref: "refReservationClassBooking"
  }, [_c('SelectedTripClassBooking', {
    attrs: {
      "get-search-flight-array": _vm.getSearchFlightArray,
      "selected-trips": _vm.selectedTrips
    }
  })], 1), _c('BRow', {
    staticClass: "mx-0 rounded-lg mb-3 mb-lg-5",
    staticStyle: {
      "background-color": "#F5F5F5"
    }
  }, [_c('BCol', {
    class: ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'px-0 py-50' : 'py-50 pl-50 pr-0',
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('ReservationClassBooking', {
    on: {
      "scroll-to-create-booking": _vm.handleSetWithCreatedBooking
    }
  })], 1), _c('BCol', {
    staticClass: "py-1",
    class: ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'px-0' : 'py-50 pr-50',
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', [_c('CardShowPriceClassBooking', {
    attrs: {
      "get-search-flight-array": _vm.getSearchFlightArray
    }
  })], 1), _c('div', {
    ref: "refCreateBooking"
  }, [_c('BCard', {
    staticClass: "mt-50 border",
    attrs: {
      "header-bg-variant": _vm.resBooking ? 'light-success' : _vm.errCreateBookingText ? 'light-danger' : 'light-info',
      "header-class": "py-50 font-medium-2 font-weight-bolder text-airline",
      "body-class": "px-50 pt-50 pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("flight['Reservation information']")) + " ")];
      },
      proxy: true
    }])
  }, [_vm.resBooking ? [_c('p', {
    staticClass: "text-success mt-50 mx-75"
  }, [_vm._v(" " + _vm._s(_vm.$t("flight.createBookingSuccessful")) + " ")]), _c('hr', {
    staticClass: "mx-1"
  }), _c('div', {
    staticClass: "d-flex-between mx-75"
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('span', {
    staticClass: "mr-25"
  }, [_vm._v(_vm._s(_vm.$t('flight.bookingCode')) + ":")]), _c('BButton', {
    staticClass: "px-1 py-50 py-lg-75 rounded-lg text-airline fw-700 font-medium-4",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.handleGoToReservation(_vm.resBooking);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.resBooking.pnr) + " ")])], 1), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('BButton', {
    staticClass: "px-1 py-50 rounded-lg mr-1 font-weight-bolder",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.goToTicketFace(_vm.resBooking);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.exportTicket')) + " ")])], 1)]), _c('hr', {
    staticClass: "mx-1"
  }), _c('BTabs', {
    staticClass: "mb-1",
    attrs: {
      "pills": ""
    }
  }, [_c('BTab', {
    attrs: {
      "title": _vm.$t('flight.shortenBooking'),
      "active": ""
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "reservations-data": {
        source: '1S'
      },
      "shorten-text": _vm.shortenBookingText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1), _vm.shortenBaggageText ? _c('BTab', {
    attrs: {
      "title": _vm.$t('flight.shortenBaggages')
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "reservations-data": {
        source: '1S'
      },
      "shorten-text": _vm.shortenBaggageText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1) : _vm._e(), !_vm.isEmpty(_vm.dataFareRules) ? _vm._l(_vm.dataFareRules, function (data, index) {
    return _c('BTab', {
      key: index,
      attrs: {
        "title": "".concat(_vm.$t('flight.fareRule'), ": ").concat(data.trip[0].departure.iataCode, "-").concat(data.trip[data.trip.length - 1].arrival.iataCode)
      }
    }, [_c('ShortenBooking', {
      attrs: {
        "shorten-text": data.fareRules,
        "loading": _vm.loadingShortenFareRules
      },
      on: {
        "get-shorten-booking-handle": _vm.getShortenFareRulesHandle
      }
    })], 1);
  }) : _vm._e()], 2)] : _vm.errCreateBookingText ? [_c('div', {
    staticClass: "ml-1 mt-1 mb-2 font-italic text-danger fw-700"
  }, [_vm._v(" - " + _vm._s(_vm.errCreateBookingText) + " ")])] : [_c('div', {
    staticClass: "ml-1 mt-1 mb-2 font-italic text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.noInformation')) + " ")])]], 2)], 1)])], 1), ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? _c('SidebarFilter', {
    scopedSlots: _vm._u([{
      key: "fee",
      fn: function fn() {
        return [_c('div', [_c('FilterClassBooking')], 1)];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('div')];
      },
      proxy: true
    }], null, false, 3710682754),
    model: {
      value: _vm.isTaskHandlerSidebarActive,
      callback: function callback($$v) {
        _vm.isTaskHandlerSidebarActive = $$v;
      },
      expression: "isTaskHandlerSidebarActive"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }